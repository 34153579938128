import React from "react";
import Gallery from './Gallery';
import './flexibleContainer.css';

function FlexibleContainer() {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);

    React.useEffect(() => {
        window.addEventListener("resize", updateWidthAndHeight);
        return () => window.removeEventListener("resize", updateWidthAndHeight);
    });

    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    return (
        <div className="flex-container">
            {Gallery(width-60)}
        </div>
    );
}

export default FlexibleContainer;