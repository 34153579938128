import React from 'react';
import ImageGallery from 'react-image-gallery';
import './gallery.css'
import 'react-image-gallery/styles/scss/image-gallery.scss'
import "react-image-gallery/styles/css/image-gallery.css";

import img1 from './assets/087.jpg'
import img2 from './assets/147.jpg'
import img4 from './assets/IMG_20240530_092444.jpg'
import img5 from './assets/IMG_20240530_092447.jpg'
import img6 from './assets/IMG_20240530_092442.jpg'
import img7 from './assets/IMG_20240530_092437.jpg'
import img8 from './assets/IMG_20240530_092440.jpg'

const IMAGES =
    [{ original: img1, thumbnail: img1 },
        { original: img2, thumbnail: img2 },
        { original: img4, thumbnail: img4 },
        { original: img5, thumbnail: img5 },
        { original: img6, thumbnail: img6 },
        { original: img7, thumbnail: img7 },
        { original: img8, thumbnail: img8 }]

function Gallery(width) {
    return (
        <div className="gallery-container">
            <div style={{width:width}}>
                <ImageGallery
                    autoPlay={true}
                    slideDuration={1400}
                    slideInterval={6000}
                    items={IMAGES}
                    thumbnailPosition="bottom"
                    additionalClass='gallery'
                    />
            </div>
        </div>
    );
}


export default Gallery;
